/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Themed } from 'theme-ui';
import Layout from '../../gatsby-theme-catalyst-core/components/layout';
import React, { useState } from 'react';
import { ContentLangChooser, IntlText, Text } from '../content/sanity-content';
import More from '../more';
import { MoreHoverContextProvider, useHoverState } from '../MoreHoverContext';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { OtherPosts } from './OtherPosts';
import { PostLink } from './PostLink';
import { Date } from './Date';
import { FlowerHeading } from '../FlowerHeading';
import ContentContainer from '../../gatsby-theme-catalyst-core/components/content-container';
// const shortenContent = (content) => {
// 	return Object.entries(content).reduce(
// 		(acc, [ key, val ]) => ({ ...acc, [key]: val ? gen_excerpt(val) : null }),
// 		{}
// 	);
// };

const PostListPage = ({ location, pageContext, data }) => {
	const nodes = data.allSanityPost.nodes;
	const featured = data.featured.nodes;

	//const flower = getImage(data.allSanityImageAsset.nodes[0]);
	//const title = data.allSanityStueckePage.nodes[0].title.note;
	//const handleClick = useLetBlink()
	return (
		<Layout pathname={location.pathname} pageContext={pageContext}>
			<ContentContainer>
				<div sx={{ width: '100%' /* borderTop: 'thin solid #d1d1d1',*/, mb: 6 }}>
					<FlowerHeading
						title="Blog"
						staticImage={
							<StaticImage
								sx={{ width: '320px', filter: 'saturate(0.5) hue-rotate(198deg)' }}
								placeholder="none"
								src="./Chris_Blume.png"
							/>
						}
					/>

					<div sx={{ maxWidth: 900, mx: 'auto', mt: 6 }}>
						{/* <GatsbyImage objectFit='contain' sx={{ mt: 5, mb: 4 }} image={flower} /> */}
						{featured.map((node) => (
							<MoreHoverContextProvider>
								<Featured node={node} />
							</MoreHoverContextProvider>
						))}
						<OtherPosts nodes={nodes} />
						{/* <svg width="800" height="2" viewBox="0 0 640 2" fill="none" xmlns="http://www.w3.org/2000/svg">
						<line y1="1" x2="640" y2="1" stroke="black" stroke-width="2" />
					</svg>

					<PlaysGrid nodes={nodes}/> */}
					</div>
				</div>
			</ContentContainer>
		</Layout>
	);
};

export const query = graphql`
	query MyPostsQuery($langCode: String!) {
		featured: allSanityPost(sort: { fields: date, order: DESC }, filter: { featured: { eq: true } }) {
			nodes {
				id
				date
				featured
				postIntl {
					slug(langCode: $langCode)
					name(langCode: $langCode)
					intlExcerpt(langCode: $langCode) {
						raw
						rawDe
						rawFr
						rawEs
						rawEn
					}
				}
				image {
					asset {
						gatsbyImageData(fit: CROP, width: 230, height: 230)
					}
				}
			}
		}
		allSanityPost(sort: { fields: date, order: DESC }, filter: { featured: { ne: true } }) {
			nodes {
				date
				postIntl {
					name(langCode: $langCode)
					slug(langCode: $langCode)
				}
			}
		}
	}
`;

export default PostListPage;

function Featured({ node }) {
	const hoverState = useHoverState();
	return (
		<div
			sx={{
				display: 'grid',
				mb: 5,
				gridTemplateColumns: [ 'auto', null, '230px auto' ],
				columnGap: 3,
				gridTemplateRows: 'auto auto 1fr',
				cursor: 'pointer'
			}}
		>
			<PostLink
				msx={{
					gridRow: [ 3, null, '1 / span 3' ],
					gridColumn: '1',
					width: '230px',
					height: '230px',
					justifySelf: 'center',
					mb: [ 3, null, 0 ],
					mt: [ 3, null, 0 ]
				}}
				node={node}
			>
				<GatsbyImage image={getImage(node.image.asset)} />
			</PostLink>
			<Themed.h1
				sx={{
					mt: '-5px',
					fontSize: 5,
					color: !hoverState ? '#00f' : '#ff6c3f',
					gridRow: [ 1, null, '1' ],
					gridColumn: [ 1, null, '2' ],
					fontWeight: 'bold',
					fontSize: 32,
					mb: 0
				}}
			>
				<PostLink node={node}>{node.postIntl.name}</PostLink>
			</Themed.h1>
			<Date node={node} />
			<div>
				<IntlText intlContent={node.postIntl.intlExcerpt}>
					{({ blocks, lang, availableLangs, onChange }) => {
						return (
							<React.Fragment>
								{availableLangs && (
									<ContentLangChooser
										availableLangs={availableLangs}
										value={lang}
										onChange={onChange}
										isExcerpt={true}
									/>
								)}
								<PostLink node={node}>
									<Text data={blocks} isExcerpt={true} />
								</PostLink>
							</React.Fragment>
						);
					}}
				</IntlText>
			</div>
		</div>
	);
}
